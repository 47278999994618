@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

body {
  background: linear-gradient(45deg, #000000 30%, $main-color 100%);
}

.main-landing-page {
  @include centerFlex(column);
  @include fullWidth();
  @include removeDefault();
  width: 100%;

  /* ===== REST OF CODE ===== */
  img {
    $sizeVW: 3vw;
    $sizeVH: 3vh;
    width: calc($sizeVW + $sizeVH);
    height: calc($sizeVW + $sizeVH);
    position: absolute;
    top: 10px;
    left: 10px;
  }

  main {
    width: 100%;
    height: 100vh;
    box-sizing: content-box;
    color: $text-color;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth; 
    scroll-snap-stop: always; 
    transition: scroll 1s ease;

    section {
      height: 100vh;
      scroll-snap-align: start;
    }

    #start {
      @include stretchWidth(100%, 100vh);
      @include centerFlex(column);
      background: transparent;

      h1 {
        font-size: $big-title-size - 1;
        font-weight: 900;
        text-align: center;
        margin: 0;
        padding: 0;
        margin-inline: 10%;
        -webkit-background-clip: text;
        background-clip: text;
        background-image: linear-gradient(45deg, rgb(221, 221, 221), rgb(0, 68, 255));
        color: transparent;

        @media screen and (max-width: 768px) {
          font-size: $big-title-size - 2.5;
          margin-inline: 5%;
        }

        span {
          color: whitesmoke;
        }
      }
    }

    #features {
      height: 100vh;
      width: 100vw;
      background: transparent;
      @include centerFlex(column);
      justify-content: flex-start;
      position: relative;

      .flash-land {
        height: 500px;
        width: 350px;
        background-color: $sec-color;
        position: absolute;
        top: calc(50% - 250px);
        border-radius: 20px;
        transform: scale(0.8);

        @media screen and (max-width: 768px) {
          height: 300px;
          width: 200px;
          top: calc(50% - 150px); 
        }

        &[pos='left'], &[pos='right'] {
          background-color: rgba($color: $sec-color, $alpha: .8)
        }

        &[pos='middle'] {
          padding: 20px;
          box-sizing: border-box;
          @include centerFlex(column);
          font-size: $text-size;

          @media screen and (max-width: 768px) {
            font-size: $text-size - .7; 
          }

          h2 {
            -webkit-background-clip: text;
            background-clip: text;
            background-image: linear-gradient(45deg, rgb(212, 212, 212), rgb(0, 89, 255));
            color: transparent;
            width: 100%;
            white-space: wrap;
            text-align: center;

            span {
              color: white;
            }
          }
        }
      }
    }

    #ai-promo {
      @include stretchWidth(100%, 100vh);
      @include centerFlex(column);
      background: transparent;
      position: relative;

      h1 {
        font-size: $big-title-size - 1;
        font-weight: 900;
        text-align: center;
        margin: 0;
        padding: 0;
        margin-inline: 10%;
        -webkit-background-clip: text;
        background-clip: text;
        background-image: linear-gradient(45deg, rgb(221, 221, 221), rgb(0, 68, 255));
        color: transparent;

        @media screen and (max-width: 768px) {
          font-size: $big-title-size - 2.5;
          margin-inline: 5%;
        }

        span {
          color: whitesmoke;
        }
      }

      & > img {
        --width: 60%;
        height: auto;
        position: absolute;
        top: calc(50% - (var(--width) / 2));
        left: calc(50% - (var(--width) / 2));
        width: var(--width);
        opacity: 0;

        @media screen and (max-width: 768px) {
          --width: 95%;
          top: calc(82% - (var(--width) / 2));
        }
      }
    }

    #join {
      @include stretchWidth(100%, 100vh);
      @include centerFlex(column);
      background: transparent;

      h1 {
        font-size: $big-title-size - 1;
        font-weight: 900;
        text-align: center;
        margin: 0;
        padding: 0;
        margin-inline: 5%;
        -webkit-background-clip: text;
        background-clip: text;
        background-image: linear-gradient(45deg, rgb(221, 221, 221), rgb(0, 68, 255));
        color: transparent;
        margin-bottom: 30px;

        @media screen and (max-width: 768px) {
          font-size: $big-title-size - 2.5;
        }

        span {
          color: whitesmoke;
        }
      }

      p {
        font-size: $text-size - .4;
        margin: 0;
        padding: 0;
        margin-inline: 5%;
        text-align: center;
        width: 100%;
        font-weight: 800;
      }
    }
  }
}

/* ====== SCROLLBAR ===== */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(58, 58, 58, .8);
  border-radius: 10px;
  border: 2px solid transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(78, 78, 78, .8);
}

/* ====== ANIMATIONS ===== */
/* ----- CLASSES ----- */
.slide-in-Y {
  animation: slide-in-Y 1s ease-in-out;
}

.slide-in-X {
  animation: slide-in-X 1s ease-in-out;
}

.card-in {
  &[pos='middle'] {
    animation: flash-mid .4s cubic-bezier(.7,.61,.53,1.06) forwards;
    z-index: 2;
  }

  &[pos='left'] {
    animation: flash-left .5s .4s ease forwards;
    z-index: 1;
  }

  &[pos='right'] {
    animation: flash-right .5s .4s ease forwards;
    z-index: 1;
  }
}

.text-fill {
  animation: text-fill .7s ease-in-out forwards;
}

.fade-in {
  animation: opacityAnim 2s ease-in-out forwards;
}

/* ----- KEYFRAMES ----- */
@keyframes slide-in-Y {
  0% {
    transform: translateY(-100%);
    opacity: 0;
    filter: blur(10px);
  }
  70% {
    opacity: .4;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes slide-in-X {
  0% {
    transform: translateX(-50%);
    opacity: 0;
    filter: blur(10px);
  }
  70% {
    opacity: .4;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes flash-mid {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes flash-left {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(.9) translateX(-150px) rotate(-20deg);
  }
}

@keyframes flash-right {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(.9) translateX(150px) rotate(20deg);
  }
}

@media screen and (max-width: 768px) {
  @keyframes flash-left {
    0% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(.9) translateX(-70px) rotate(-20deg);
    }
  }

  @keyframes flash-right {
    0% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(.9) translateX(70px) rotate(20deg);
    }
  }

  ::-webkit-scrollbar {
    width: 0px;
  }
}

@keyframes opacityAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: .2;
  }
}

@media(prefers-reduced-motion: reduce) {
  .slide-in-Y, .slide-in-X, .card-in, .text-fill {
    animation: none;
  }
}