@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.profile-page-main {
  @include centerFlex(column);
  @include fullWidth(hidden);
  @include removeDefault();
  align-items: center;
  overflow-y: auto;
  background: url('../../assets/images/moving-bg-squares.svg') repeat;
  background-color: $main-color;
  background-size: cover;
  background-position: 0 0;
  animation: moveBG linear 30s infinite forwards;
  box-sizing: border-box;
  color: $text-color;

  .top-buttons {
    position: fixed;
    height: 70px;
    width: 100%;
    top: 0;
    right: 0;
    @include centerFlex();
    justify-content: flex-end;

    .sign-out-btn {
      margin: 10px;
    }
  }

  main {
    @include stretchWidth();
    @include centerFlex(column);
    max-width: 1000px;
    margin: 50px;
    margin-top: 120px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 20px;

    @media screen and (max-width: 768px) {
      margin: 20px;
      margin-top: 90px;
    }

    .container {
      @include stretchWidth();
      @include centerFlex(column);
      justify-content: flex-start;
      background-color: rgba($color: $sec-color, $alpha: .1);
      border: 1px solid white;
      border-radius: 30px;
      backdrop-filter: blur(10px);
    }

    /* ====== Profile Sections ===== */
    .usr-stats {
      @include centerFlex(row);
      justify-content: space-between;
      width: 100%;

      img{
        $size: 200px;
        border-radius: 50%;
        width: $size;
        height: $size;
        min-width: $size;
        min-height: $size;
        max-width: $size;
        max-height: $size;
        margin: 20px;
        margin-top: 20px;
        object-fit: cover;

        @media screen and (max-width: 768px) {
          $size: 100px;
          height: $size;
          width: $size;
          margin-left: 3vw;
        }
      }

      & > div {
        @include centerFlex(column);
        text-align: left;
        width: stretch;
        font-size: 1rem;
        padding: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        
        @media screen and (max-width: 768px) {
          font-size: 2vw;
        }

        & > * {
          width: 100%;
        }
      }
    }

    /* ====== User Settings ===== */
    .user-config {
      @include stretchWidth();
      @include centerFlex(column);

      & > div {
        @include stretchWidth();
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        gap: 20px;
        margin: 20px;
        
        .profile-settings-left {
          width: 1fr;
        }

        .profile-settings-right {
          width: 1fr;
        }
      }
    }
  }
}

.separator {
  width: 100%;

  .line {
    width: stretch;
    height: 1px;
    background-color: white;
    margin: 10px;
  }
}

.on-top {
  z-index: 5;
}

@keyframes moveBG {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -100dvw -100dvh;
  }
}