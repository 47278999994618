/* 
========================================
ROOT STYLES
========================================
*/
:root {
  --bg-color: #9bfb00;
  --bg-accent: #e9a20a;

  --primary-color: #ff5e00;
  --secondary-color: #ff6767;
  --accent-color: #f70000;

  --main-color: #1c0e45;
  --sec-color: #003687;
  --third-color: #005592;

  --bg-gradient: linear-gradient(-135deg, var(--bg-color), var(--accent-color));

  --morph-shadow: 12px 12px 12px rgba(0, 0, 0, 0.1), -12px -12px 12px white;
  --box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.jsx-parser {
  margin: 0;
  padding: 0;
  width: stretch;
  height: stretch;
}

a[href="https://neat.firecms.co"] {
  display: none !important;
  color: transparent !important;
  content: none !important;
}

b{
  font-weight: 900;
}