@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

// Styles for asking prompt for manual or AI generation
.alert-main {
  @include fullWidth();
  @include centerFlex();
  box-sizing: border-box;

  .inner {
    @include centerFlex(column);
    @include stretchWidth(60%, 60%);
    min-width: 300px;
    min-height: 200px;
    max-width: 1000px;
    max-height: 700px;
    padding: 20px;
    // background: rgba($color: white, $alpha: 0.7);
    // backdrop-filter: blur(20px);
    border-radius: 20px;
    text-align: center;
    justify-content: flex-start;

    @media screen and (max-width: 768px) {
      @include stretchWidth(90%, 100%);
      max-height: 100dvh;
    }

    h1 {
      margin-inline: 30px;
      background: linear-gradient(-135deg, lighten($main-color, 40%), darken(white, 20%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; 

      @media screen and (max-width: 768px) {
        font-size: 1.5rem;
      }
    }

    .selection {
      @include centerFlex();
      gap: 50px;
      margin-top: 40px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
      }
      
      .container {
        height: 400px;
        width: 300px;
        background: linear-gradient(45deg, darken($sec-color, 20%) 0%, $main-color 100%);
        border-radius: 20px;
        transition: transform 0.3s ease;

        @media screen and (max-width: 768px) {
          height: 200px;         
        }

        &:hover {
          transform: scale(1.07);
          cursor: pointer;
        }
      }

      .icon {
        color: white;
        font-size: 150px;

        @media screen and (max-width: 768px) {
          font-size: 70px;
        }
      }

      .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > h1 {
          font-size: 1.3rem;

          @media screen and (max-width: 768px) {
            font-size: .9rem;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > h1 {
          font-size: 1.3rem;

          @media screen and (max-width: 768px) {
            font-size: .9rem;
          }
        }

        span {
          @include centerFlex();
          align-items: flex-end;
          gap: 10px;
          width: stretch;

          & > h1 {
            margin: 0;
            font-size: 1.3rem;
          }

          & > img {
            object-fit: contain;
            width: 30%;
            height: auto;
          }
        }
      }
    }
  }
}

// Styles for the AI generation prompt
.AI-generate-prompt {
  @include centerFlex(column);
  height: 600px;
  width: 420px;
  background: linear-gradient(45deg, darken($sec-color, 20%) 0%, $main-color 100%);
  color: white;
  padding: 20px;
  padding-inline: 60px;
  border-radius: 20px;
  box-sizing: border-box;

  & > h1 {
    font-size: 1.4rem;
    text-align: center;
    background: linear-gradient(-135deg, lighten($main-color, 40%), darken(white, 20%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }

  & > .icon {
    font-size: 130px;

    @media screen and (max-width: 768px) {
      font-size: 100px;
    }
  }
}

// Main Editor styles
.main-container {
  @include fullWidth();
  @include centerFlex();
  box-sizing: border-box;

  .main-edit-cont {
    @include centerFlex(column);
    @include stretchWidth();
    background: transparent;
    justify-content: flex-start;

    & > .menu-bar {
      @include centerFlex();
      height: 70px;
      width: stretch;
      background: linear-gradient(
        45deg, 
        darken(rgba($color: $sec-color, $alpha: .7), 10%) 0%, 
        lighten(rgba($color: $main-color , $alpha: .7), 20%) 100%
      );
      backdrop-filter: blur(20px);
      border-radius: 20px;
      padding: 5px;
      margin: 20px;
      margin-inline: 60px;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        margin-inline: 20px;
      }

      & > div {
        @include centerFlex();
        justify-content: flex-start;
        width: 30%;
      }

      & > div:nth-child(1) > h2 {
        font-size: 1.3rem;
        margin-inline: 20px;
        color: white;
      }

      & > div:nth-child(2) {
        @include centerFlex();

        & > h1 {
          background: linear-gradient(-135deg, lighten($main-color, 40%), darken(white, 20%));
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      & > div:nth-child(3) {
        @include centerFlex();
        justify-content: flex-end;
        color: white;
        font-size: 1rem;
      }
    }

    & > .main-edit {
      height: calc(100dvh - 180px);
      width: stretch;
      background: linear-gradient(
        45deg, 
        darken(rgba($color: $sec-color, $alpha: .7), 10%) 0%, 
        lighten(rgba($color: $main-color , $alpha: .7), 20%) 100%
      );
      backdrop-filter: blur(20px);
      border-radius: 20px;
      padding: 10px;
      margin: 20px;
      margin-inline: 60px;
      display: grid;
      grid-template-columns: 300px calc(100dvw - 800px - 120px - 40px - 20px) 500px;
      gap: 20px;

      @media screen and (max-width: 768px) {
        margin-inline: 20px;
        width: stretch;
        gap: 0px;
        display: flex;
      }

      & > div {
        border: white 2px, solid;
        border-radius: 15px;
      }

      & > .middle {
        @include centerFlex(column);
        gap: 20px;

        @media screen and (max-width: 768px) {
          gap: 0;
          width: 100%;
        }

        & > .flash-cont {
          height: stretch;
          width: 100%;
        }

        & > .bottom-buttons {
          height: 70px;
        }
      }

      .left, .right {
        @include centerFlex(column);
        color: white;
        padding: 10px;
        box-sizing: border-box;
        text-align: center;
        background: rgba($color: white, $alpha: .3);
        backdrop-filter: blur(20px);

        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
  }

  .settings-panel {
    width: 90vw;
    height: 90vh;
    position: fixed;
    top: calc(50% - 45vh);
    left: calc(50% - 45vw);
    background: linear-gradient(
      45deg, 
      darken(rgba($color: $sec-color, $alpha: .7), 10%) 0%, 
      lighten(rgba($color: $main-color , $alpha: .7), 20%) 100%
    );
    backdrop-filter: blur(20px);
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
  }
}

.hidden {
  transform: scale(0) translateY(-100vh);
}

// Styles for animating
.animate-in {
  animation: animateIn 0.5s ease forwards;
}

.animate-out {
  animation: animateIn reverse 0.5s ease forwards;
}

// Styles for small mobile bar
.menu-bar-in {
  height: auto !important;
}

.menu-bar-out {
  height: 80px !important;

  button:not(:nth-child(1)) {
    display: none;
  }
}

@keyframes blur-in {
  0% {
    backdrop-filter: blur(0px);
    opacity: 0;
  }
  100% {
    backdrop-filter: blur(20px);
    opacity: 1;
  }
}

// Keyframes
@keyframes animateIn {
  0% {
    transform: scale(0) translateY(-100vh);
    opacity: 0;
  }
  80% {
    transform: scale(1.1) translateY(0);
    opacity: .7;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}