@mixin centerFlex($direction: row) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $direction;
}

@mixin fullWidth($overflow: hidden) {
  width: 100dvw;
  height: 100dvh;
  overflow: $overflow;
}

@mixin stretchWidth($width: stretch, $height: stretch) {
  width: $width;
  height: $height;
}

@mixin removeDefault($margin: 0, $padding: 0, $list-style: none) {
  margin: $margin;
  padding: $padding;
  list-style: $list-style;
}

@mixin mobileView($dv: bool, $sv: bool, $lv: bool) {
  @if $dv {
    width: 100dvw;
    height: 100dvh;
  } 
  @else if $sv {
    width: 100svw;
    height: 100svh;
  }
  @else if $lv {
    width: 100lvw;
    height: 100lvh;
  }
}