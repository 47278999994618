
$bg-color: #9bfb00;
$bg-accent: #e9a20a;

$primary-color: #ff5e00;
$secondary-color: #ff6767;
$accent-color: #f70000;

$main-color: #1c0e45;
$sec-color: #003687;
$third-color: #005592;

$bg-gradient: linear-gradient(-135deg, $bg-color, $accent-color);
$morph-shadow: 12px 12px 12px rgba(0, 0, 0, 0.1), -12px -12px 12px white;

$box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);

$big-title-size: 4rem;
$small-title-size: 2rem;
$text-size: 1.5rem;

$text-color: white;