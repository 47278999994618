@use "../../styles/variables.scss" as *;
@use "../../styles/mixins.scss" as *;

.not-found-main {
  --padding: 50px;
  position: relative;
  margin: 0;
  padding: var(--padding);
  width: stretch;
  height: calc(100vh - var(--padding) * 2);
  overflow: hidden;
  background: linear-gradient(
    45deg, 
    darken(rgba($color: $sec-color, $alpha: .7), 10%) 0%, 
    lighten(rgba($color: $main-color , $alpha: .7), 20%) 100%
  );
  color: white;
  font-size: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}