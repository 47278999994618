@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.navbar-main {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background: transparent;
  z-index: 1000;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100px;
    width: 170px;
    z-index: -1;
    height: 70px;
    border-left: 100px solid transparent;
    border-right: 200px solid transparent;
    border-top: 70px solid $sec-color;
    transition: all .3s ease;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -100px;
    width: 150px;
    z-index: -1;
    height: 70px;
    border-right: 100px solid transparent;
    border-left: 200px solid transparent;
    border-top: 70px solid $sec-color;
    transition: all .3s ease;
  }

  .navbar-background {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 170px;
    height: 70px;
    mix-blend-mode: screen;
    background: transparent;
    border: transparent;
    z-index: 5;
  }

  .navbar-item-1 {
    margin-left: 20px;

    & > h1 {
      padding: 10px;
      padding-right: 30px;
      padding-left: 30px;
      font-size: 2rem;
      font-weight: 1000;
      font-style: italic;
      background: linear-gradient(90deg, #ffffff, #2582fa);
      -webkit-text-stroke: 10px transparent;
      -webkit-text-fill-color: var(--sec-color);
      background-clip: text;
      -webkit-background-clip: text;
      z-index: 4;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 30px;
      background-color: $sec-color;
      z-index: -1;

    }
  }

  .navbar-item-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
    gap: 20px;

    .nav-button {
      color: white;
      font-size: 1.2rem;
      transform: translateY(0);
      transition: all .3s ease;
      width: fit-content;
      will-change: padding, transform;

      & > svg {
        margin-right: 0 !important;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(transparent 0%, $sec-color 130%);
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        background: white;
        transition: all .3s ease;
      }

      &:hover {
        padding-bottom: 10px;
        cursor: pointer;

        &::after {
          height: 2px;
        }
      }
    }
  }
}

.nav-hamburger {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;

  .ham-side-menu {
    position: fixed;
    top: 70px;
    right: 0;
    transform: translateX(75px);
    width: 70px;
    height: 250px;
    @include centerFlex(column);
    justify-content: flex-start;
    background-color: rgba($color: $sec-color, $alpha: .8);
    border-left: rgba($color: #fff, $alpha: .3) 1px solid;
    transition: all 0.4s ease;
    z-index: 1001;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      right: 0; 
      transform: translateX(0);
      width: 0;
      height: 0;
      border-left: 70px solid transparent; 
      border-right: 0 solid transparent;
      border-top: 300px solid rgba($color: $sec-color, $alpha: .8);
    }

    &::before {
      content: '';
      position: absolute;
      top: 246px;
      right: 0; 
      transform: translateX(-34px) rotate(-13deg);
      background-color: rgba($color: #fff, $alpha: .5);
      width: 1px;
      height: 320px;
      z-index: inherit;
    }
    
    & > h1 {
      transform: translateX(0);
      transition: all .3s ease;
      width: fit-content;
      will-change: padding, transform;

      & > svg {
        margin-right: 0 !important;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(transparent 0%, rgba($color: $sec-color, $alpha: .8) 130%);
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 70%;
        background: white;
        transition: all .3s ease;
      }

      &:hover {
        padding-right: 10px;
        cursor: pointer;

        &::after {
          width: 2px;
        }
      }
    }
  }

  .ham {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 70px;
    width: 70px;
  }
  
  .hamRotate.ham-active {
    transform: rotate(45deg);
  }
  
  .hamRotate180.ham-active {
    transform: rotate(180deg);
  }

  .line {
    fill:none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke:#ffffff;
    stroke-width:5.5;
    stroke-linecap:round;
  }
  
  .ham6 {
    .top {
      stroke-dasharray: 40 172;
    }
  
    .middle {
      stroke-dasharray: 40 111;
    }
  
    .bottom {
      stroke-dasharray: 40 172;
    }
  
    &.ham-active {
      .top {
        stroke-dashoffset: -132px;
      }
  
      .middle {
        stroke-dashoffset: -71px;
      }
  
      .bottom {
        stroke-dashoffset: -132px;
      }
    }
  }
}

.move-in {
  animation: smooth-in .7s;
  animation-play-state: running;
  translate: translateY(0);

  &[anim-delay = "1"] {
    animation-delay: 0s;
  }

  &[anim-delay = "2"] {
    animation-delay: 0.15s;
  }

  &[anim-delay = "3"] {
    animation-delay: 0.3s;
  }
}

@keyframes smooth-in {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .navbar-main {
    .navbar-item-1 {
      & > h1 {
        padding-left: 5px;
      }
    }

    &::after {
      width: 100%;
    }
  }
  
}

.hidden {
  display: none;
}