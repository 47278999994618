/*
=============================================
HOME PAGE STYLES
=============================================
*/
p, h2 {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

h2 {
  white-space: nowrap;
}

.title-p {
  display: -webkit-box;
  height: 5ch;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.home-page-main {
  --padding: calc(1vw + 1vh);
  position: relative;
  margin: 0;
  padding: var(--padding);
  padding-top: calc(var(--padding) + 40px);
  padding-bottom: calc(var(--padding) - 40px);
  width: 1fr;
  height: calc(100vh - var(--padding) * 2);
  overflow-x: hidden;
  background: var(--main-color);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  color: white;

  .set-display {
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    background-color: var(--sec-color);
    border-radius: 10px;
    padding: 10px;
    box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.4);
    width: 1fr;
    height: 120px;
    margin: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    transition: all .3s ease;
  }

  /* Ensure specificity by targeting the class names directly */
  .display-swiper:hover .set-display {
    transform: scale(1.05);
  }

  .recent-sets {
    width: 100%;
    height: auto;
  }
}

.title-h2 {
  font-size: 1.3rem;
}