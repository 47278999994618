@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.sing-in-main {
  @include centerFlex();
  @include stretchWidth(100dvw, 100dvh);

  video::-webkit-media-controls {
    display: none;
  }

  .bg-video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    z-index: -1;

    /* Safari-specific fix */
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        &::-webkit-media-controls {
          display: none !important;
        }
      }
    }

    &::-webkit-media-controls {
      display: none !important;
    }
  }

  .signIn-inner {
    --margin: calc(2vw + 2vh);
    position: relative;
    @include centerFlex(row);
    @include stretchWidth(calc(100dvw - 2 * var(--margin)), calc(100dvh - 2 * var(--margin)));
    background-color: rgba($color: $sec-color, $alpha: .6);
    border-radius: 30px;
    margin: var(--margin);
    border: 1px solid white;
    backdrop-filter: blur(10px);
    z-index: 1;

    .cover {
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
      height: 100%;
      border-bottom-right-radius: 30px;
      border-top-right-radius: 30px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      background-color: rgba($color: $sec-color, $alpha: 1);
      transition: all .5s ease;
      transform: translateX(0);
      z-index: 2;

      &.after {
        transform: translateX(-100%);
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
      }
    }

    .sign-in-left-side {
      width: 100%;
      @include centerFlex(column);
      margin: 0;
      padding: 20px;
      box-sizing: border-box;
      max-width: 700px;
    }

    .sign-in-right-side {
      width: 100%;
      @include centerFlex(column);
      margin: 0;
      padding: 20px;
      box-sizing: border-box;
      transform: scale(0);
      display: none;
      max-width: 700px;
    }

    h1 {
      color: white;
      margin: 0;
      padding: 0;
      font-weight: 1000;
      letter-spacing: 3px;
      font-style: italic;
      font-size: calc($big-title-size - .5rem);
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke: 1px white;
      text-decoration: underline;
      text-decoration-color: white;
      text-decoration-thickness: 1px;
      text-decoration-style: double;
      text-underline-offset: 5px;
    }

    @media screen and (max-width: 768px) {
      h1[type="sign-up"] {
        font-size: calc($big-title-size - 1rem);
        margin-left: 35px;
      }
    }

    .or-divider {
      width: 100%;
      height: 1px;
      background-color: white;
      margin: 20px 0;
    }
  }
}

.fly-in {
  animation: fly-in 1s ease;
  animation-fill-mode: forwards;
}

.fly-out {
  animation: fly-out 1s ease;
  animation-fill-mode: forwards;
}

@keyframes fly-in {
  0% {
    transform: scale(0) rotate(180deg);
  }
  80% {
    transform: scale(1.1) rotate(360deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}

@keyframes fly-out {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.1) rotate(0deg);
  }
  100% {
    transform: scale(0) rotate(180deg);
  }
}