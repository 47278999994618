.flash-card-V-main {
  display: flex;
  justify-content: center;  
  align-items: center;
  width: 100dvw;
  height: 100dvh;
  
  .inner-main-view {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    color: white;
    width: 100%;
    height: 100%;
    background-color: #1c0e45;

    &::after {
      content: '';
      position: fixed;
      top: 77px;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.3); // 0.7 opacity
      backdrop-filter: blur(10px); // Adjust the blur amount as needed
      z-index: 10;
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s ease;
    }

    &::before {
      content: '';
      position: fixed;
      top: 77px;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.3); // 0.7 opacity
      backdrop-filter: blur(10px); // Adjust the blur amount as needed
      z-index: 10;
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s ease;
    }

    &.before::before {
      opacity: 1;
      pointer-events: auto;
    }

    &.move::after {
      opacity: 1;
      pointer-events: auto;
    }

    .inner-inn-main-view {
      display: flex;
      justify-content: center;
      align-items: center;

      & > h2 {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .side-bar {
        --width: 20vw;
        position: absolute;
        bottom: 0;
        right: 0;
        height: calc(100% - 77px);
        width: auto;
        transform: translateX(var(--width));
        background-color: var(--main-color);
        transition: all 0.3s ease;
        box-shadow: rgba(255, 255, 255, 0.419) 0px 5px 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        z-index: 100;
        box-sizing: border-box;
        padding: 10px;
        padding-top: 20px;

        &.move {
          transform: translateX(0);
        }
      }
    }
  }
}

.flash-card-main {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  
  .flash-card-inn {
    position: relative;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 20px;
    width: 40%;
    height: 80%;
    margin: 15px;
    transform-style: preserve-3d;
    transition: all 0.5s;
    transform: scale(1);

    .flash-items {
      background-color: transparent;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;  
      user-select: none;

      .card-label {
        position: absolute;
        top: 10px;
        right: 20px;
        font-weight: 700;
      }
    }

    .flash-items:not(:nth-child(1)) {
      transform: rotateY(180deg);
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.flashSwiper {
  position: relative;
  background-color: transparent;

  .swiper-slide {
    background-color: transparent;
    color: white;
    background: none;
  }

  .swiper-slide-shadow {
    background: none !important;
  }
}

.navigation-button-main {
  background-color: var(--sec-color);
  border-radius: 40px;
}

.settings-popup-main {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  margin: 15dvh 25dvw;
  width: stretch;
  height: stretch;
  transform: scale(0);
  opacity: 0.5;
  border-radius: 20px;
  background-color: rgba(#1c0e45, .65);
  z-index: 1000;
  transition: all 0.3s ease;  

  .settings-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5vw;

    .settings-icon {
      padding: 20px;
      border-radius: 20px;
      background-color: var(--sec-color);
      color: white;
      border: 2px white solid;
      transition: all 0.3s ease;
      width: 80px;
      height: 80px;

      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    }

    .settings-icon-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .settings-label-inner {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .settings-icon-label {
          margin-top: 10px;
        }
      }
    }
  }
}
/*
---------------------------
CLASSES
---------------------------
*/
.flip-card {
  transform: rotateY(180deg);
}

.blur {
  filter: blur(20px);
}

.popup-move {
  animation: popup-move-in .7s ease forwards;
  animation-play-state: running;
}

.popup-move-out {
  animation: popup-move-out .7s ease forwards;
  animation-play-state: running;
}

@keyframes popup-move-in {
  0% {
    opacity: 0;
    transform: scale(0) translateY(-100vh);
  }

  70% {
    opacity: 0.7;
    transform: scale(1) translateY(+15vh);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes popup-move-out {
  0% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }

  20% {
    opacity: 0.2;
    transform: scale(1) translateY(+15vh);
  }

  100% {
    opacity: 0;
    transform: scale(0) translateY(-100vh);
  }
}
/*
============================
MEDIA QUERIES
============================
*/
@media screen and (max-width: 768px) {
  .side-bar {
    --width: 40vw !important;
  }

  .inner-inn-main-view > h2, .inner-inn-main-view {
    max-width: 60vw;
  }

  .settings-popup-main {
    margin: 15dvh 100px 15dvh 2dvw;
  }

  .flash-items {
    h1 {
      font-size: 1.4rem;
    }
  }
}

@media screen and (max-width: 1080px) and (min-width: 769px) {
  .settings-popup-main {
    margin: 15dvh 13dvw 15dvh 10dvw;
  }
}

@media screen and (max-width: 1500px) {
  .settings-icons {
    flex-direction: column;
  }
}

@media screen and (max-width: 1500px) and (min-width: 769px) {
  .side-bar {
    --width: 30vw !important;
  }

  .inner-inn-main-view > h2, .inner-inn-main-view {
    max-width: 80vw;
  }

  .flash-items > h1 {
    font-size: 1.7rem;
  }
}

@media screen and (min-width: 1501px) {
  .inner-inn-main-view > h2, .inner-inn-main-view {
    max-width: 40vw;
  }
}

@media screen and (min-width: 1501px) and (max-width: 1800px) {
  .flash-items > h1 {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1801px) {
  .flash-items > h1 {
    font-size: 2.5rem;
  }
}