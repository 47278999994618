@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.file-view-main {
  @include mobileView($dv: true);

  main {
    @include stretchWidth(
      $width: stretch,
      $height:calc(100dvh - 65px)
    );
    @include centerFlex(column);
    color: $text-color;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: rgba($color: $sec-color, $alpha: .3);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    margin: 40px;

    @media screen and (max-width: 768px) {
      margin: 15px;

      @include stretchWidth(
        $width: stretch,
        $height:calc(100dvh - 35px)
      );
    }

    .top-bar {
      @include centerFlex(row);
      justify-content: flex-start;
      gap: 20px;
      height: 70px;
      min-height: 70px;
      width: stretch;
      border-radius: 20px;
      margin: 5px;
      background-color: rgba($color: $sec-color, $alpha: .5);

      @media screen and (max-width: 768px) {
        justify-content: space-between;
        gap: 0;
      }

      &>.nice-btn-last {
        @media screen and (max-width: 768px) {
          margin-right: 10px;
        }
      }
    }

    .files-main-cont {
      max-height: calc(100dvh - 80px - 70px);
      border-radius: 0;
      width: stretch;

      .folder-outer-cont {
        margin-left: 20px;
        transition: all .3s ease-in-out;
        border-radius: 0;

        .folder-cont {
          @include centerFlex(row);
          align-items: center;
          justify-content: flex-start;
          padding: 5px;
          transition: all .3s ease-in-out;

          @media screen and (min-width: 769px) {
            &:hover {
              background-color: rgba($color: lighten($main-color, 35%), $alpha: .6);
              border-radius: 10px;
              cursor: pointer;
            }
          }
        }
      }

      .file-cont {
        @include centerFlex(row);   
        align-items: center;
        justify-content: flex-start;  
        margin-left: 40px;
        padding: 5px;
        transition: all .3s ease-in-out;

        @media screen and (min-width: 769px) {
          &:hover {
            background-color: rgba($color: lighten($main-color, 35%), $alpha: .6);
            border-radius: 10px;
            cursor: pointer;
          }
        }
      }
    }

    .file-manager-cont {
      margin: 5px;
    }

    .root-dir {
      overflow-y: scroll;
      width: stretch;
      height: calc(100dvh - 80px - 70px - 40px);
      background-color: rgba($color: $sec-color, $alpha: .5);
      padding: 10px;
      border-radius: 20px;

      &>div{
        margin-left:0;
      }
    }

    .main-cont-files {
      width: stretch;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      height: stretch;
      margin: 5px;
      gap: 20px;

      &>div:nth-child(1) {
        width: 60%;

        @media screen and (max-width: 1250px) {
          width: 100%;
        }
      }

      &>div:nth-child(2) {
        width: 40%;
      }

      .preview-main-cont {
        height: stretch;
        border-radius: 20px;
        width: 40%;
        max-width: 40%;
        min-width: 40%;
        margin: 5px;
        background-color: rgba($color: $sec-color, $alpha: .5);

        .flash-view-preview {
          margin: 10px;
          border-radius: 10px;
          height: calc(100% - 20px);
        }

        .flashcard-group {
          width: 200px;
          height: 400px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;

          .flashcard {
            width: 100px;
            height: 120px;
            background-color: $third-color;
            border-radius: 10px;
            outline: white 2px solid;

            &:nth-child(1) {
              transform: rotate(-20deg) translateX(10px) translateY(10px);
            }

            &:nth-child(2) {
              z-index: 2;
            }

            &:nth-child(3) {
              transform: rotate(20deg) translateX(-10px) translateY(10px);
            }

          }
        }
      }
    }

    .import-preview-blur-cont {
      width: 100dvw;
      height: 100dvh;
      position: absolute;
      top: -40px;
      left: -40px;
      background-color: rgba($color: $main-color, $alpha: .5);
      backdrop-filter: blur(20px);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;

      .import-inner-box {
        width: 500px;
        height: 500px;
        background-color: rgba($color: $sec-color, $alpha: .7);
        border-radius: 20px;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media screen and (max-width: 768px) {
          width: 90%;
          height: 90%;
        }

        &>hr {
          width: 80%;
        }

        &>p {
          margin-bottom: 20px;
        }

        .import-box {
          width: 90%;
          height: 70%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          border-radius: 20px;
          padding: 20px;
          z-index: 1000;
          background-color: rgba($color: $third-color, $alpha: .7);
          outline: white 5px dotted;
          overflow: hidden;
          transition: all .7s ease;

          &>input {
            margin-top: 40px;
          }

          &:hover {  
            position: relative;
            outline: none;

            &::before {
              content: '';
              position: absolute;
              z-index: -2;
              left: -50%;
              top: -50%;
              width: 200%;
              height: 200%;
              background-color: rgba($color: $third-color, $alpha: .3);
              background-repeat: no-repeat;
              background-position: 0 0;
              background-image: conic-gradient(transparent, rgb(255, 255, 255), transparent 30%);
              animation: rotate 4s linear infinite;
            }
            
            &::after {
              content: '';
              position: absolute;
              z-index: -1;
              left: 6px;
              top: 6px;
              width: calc(100% - 12px);
              height: calc(100% - 12px);
              background: $third-color;
              border-radius: 17px;
            }
          }
        }
      }
    }
  }
}

// ======== CONDITIONAL STYLES ==========
.file-cont, .folder-cont {
  &.selected {
    background-color: rgba($color: lighten($main-color, 35%), $alpha: .6);
    border-radius: 10px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes opacityChange {
  50% {
    opacity:.5;
  }
  100% {
    opacity: 1;
  }
}