body {
  height: 100dvh;
  width: 100dvw;
}

.are-you-sure-popup {
  position: absolute;
  top: 0;
  left: 0;
  margin: 35dvh 30dvw 35dvh 30dvw;
  width: stretch;
  height: 30dvh;
  background-color: rgba(#1c0e45, .5);
  border: 2px solid var(--sec-color);
  backdrop-filter: blur(30px);
  border-radius: 10px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: scale(0);
  flex-direction: column;
}

.alert-img {
  object-fit: cover;
  width: 50px;
  height: 50px;
  margin-right: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.buttons {
  width: stretch;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.bg-blur {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .2);
  backdrop-filter: blur(10px);
  width: 100dvw;
  height: 100dvh;
  opacity: 0;
  z-index: -1;
  transition: all 0.4s ease;
}

.blur-active {
  opacity: 1;
  z-index: 999;
}

.popup-move-in-mn {
  animation: popup-move-in .7s ease forwards;
  animation-play-state: running;
}

.popup-move-out-mn {
  animation: popup-move-out .7s ease forwards;
  animation-play-state: running;
}

@keyframes popup-move-in {
  0% {
    opacity: 0;
    transform: scale(0) translateY(-100vh);
  }

  70% {
    opacity: 0.7;
    transform: scale(1) translateY(+15vh);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes popup-move-out {
  0% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }

  20% {
    opacity: 0.2;
    transform: scale(1) translateY(+15vh);
  }

  100% {
    opacity: 0;
    transform: scale(0) translateY(-100vh);
  }
}

/* 
===========================
MEDIA QUERIES
===========================
*/
@media screen and (max-width: 768px) {
  .are-you-sure-popup {
    margin: 35dvh 10dvw 35dvh 10dvw;
    height: 30dvh;
  }

  .alert-img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .buttons {
    width: 100%;
  }
}